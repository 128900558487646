body {
  background-color: var(--lightGrey);
}
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0 !important;
  .number {
    display: inline-flex;
    flex: 1;
    font-weight: 400;
    font-size: 1.125rem;
    color: var(--grey);
    align-items: center;
    white-space: nowrap;
    &:after {
      content: '';
      width: 100%;
      border-bottom: 1px solid var(--grey);
      margin: 0 1rem;
    }
    .number-count {
      margin-left: 0.5rem;
    }
  }
  .end {
    flex: 1;
  }
}
h2 {
  font-size: 2.25rem;
  line-height: 2.625rem;
  color: var(--black);
  margin: 0;
}
h3, h4, h5, h6 {
  font-size: 1.25rem;
  line-height: 1.37rem;
  color: var(--black);
  font-weight: 400;
  margin: 0;
}
p {
  word-break: break-all;
}
p,
ul li,
ol li {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0.5rem 0 !important;
}
p:empty {
  margin: 0 !important;
}
.lazyload-wrapper {
  width: 100%;
}

// Pdf
.content-body {
  position: relative;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 11pt;
  line-height: 150%;
  color: var(--darkerGrey);
}
.frontpage-content {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto;
}
.page-break {
  height: 100%;
}