@import '../../css/global/index.scss';
@import '../../css/mixins/responsiveness.scss';

.wrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.75rem 1.25rem;
  margin: 0 auto;
  width: 100%;
  max-width: $lg;
  border-radius: $smallerBorderRadius;
  color:  $white;
  z-index: 99999999;
  @include below(lg) {
    border-radius: 0;
    top: 0;
  }
  .content {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.4;
  }
  .leftIcon {
    font-size: 1.25rem;
    line-height: 1.25rem;
    position: relative;
    top: 2px;
  }
  .closeButton {
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: $white;
    position: relative;
    top: 1px;
    &:hover {
      &:before {
        content: '';
        position: absolute;
        border-radius: 50%;
        padding: 4px;
        left: -7px;
        right: -7px;
        top: -3px;
        bottom: -2px;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
  &.error {
    background-color: $red;
  }
  &.success {
    background-color: $darkTeal;
  }
  &.warning {
    background-color: $yellow;
  }
}